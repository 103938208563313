import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Header from "../components/organisms/header";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignNavHeader({ props }) {
  const designProps = props;

  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Header" />
      <h2>Header</h2>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            title="Header"
            component={Header}
            type="nav"
            props={designProps}
          />
          <p className="design__text p2">
            The header component has a prop named extras that can pass
            storeContext to manage global state, constants, and environment
            variables:
          </p>
          <CodeBlock language="jsx">
            {`extras={{
  storeContext: StoreContext,
  reviewStars: StoreInfo.data.storeInfo.edges[0].node.reviewStars,
  headerStyles,
  ...CONSTANTS,
  domain: process.env.DOMAIN,
  OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
  PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
  FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
  KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
}}`}
          </CodeBlock>
        </div>
        <div className="design__story-section">
          <DesignProps component={Header} title="Header Properties" />
        </div>
      </div>
    </div>
  );
}

DesignNavHeader.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignNavHeader.defaultProps = {
  props: {},
};

export default DesignNavHeader;
